import {
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
  FOCUS_SCROLL_DISABLED_ATTRIBUTE,
} from '../constants';
import { parseFocusScrollPadding } from './parse-focus-scroll-padding';
import { scrollIntoView } from './scroll-into-view';

function isFocusScrollDisabled(element: Element, axis: 'x' | 'y') {
  return element.matches(
    `[${FOCUS_SCROLL_DISABLED_ATTRIBUTE}=""], [${FOCUS_SCROLL_DISABLED_ATTRIBUTE}="${axis}"]`,
  );
}

export function scrollFocusedElementIntoView(focusedElement: Element, axis: 'x' | 'y') {
  // If focus scroll is disabled for the focused element, do nothing.
  if (isFocusScrollDisabled(focusedElement, axis)) {
    return;
  }

  // Get scroll container for this axis.
  const scrollContainer =
    focusedElement.closest(
      `[${FOCUS_SCROLL_CONTAINER_ATTRIBUTE}=""], [${FOCUS_SCROLL_CONTAINER_ATTRIBUTE}="${axis}"]`,
    ) || focusedElement;

  // If focus scroll is disabled for the container, do nothing.
  if (isFocusScrollDisabled(scrollContainer, axis)) {
    return;
  }

  // Get final scroll to target for this axis.
  const scrollToTarget =
    scrollContainer.querySelector(
      `[${FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE}=""], [${FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE}="${axis}"]`,
    ) || scrollContainer;

  // If focus scroll is disabled for the target, do nothing.
  if (isFocusScrollDisabled(scrollToTarget, axis)) {
    return;
  }

  // Scroll the target into view.
  scrollIntoView(scrollToTarget, {
    axis,
    edgePadding: parseFocusScrollPadding(scrollToTarget),
  });
}
