import { forwardRef } from 'react';
import {
  BaseButton,
  BaseAnchorButton,
  BaseButtonProps,
  BaseAnchorButtonProps,
} from '../base-button/base-button';
import styles from './button.module.css';

interface SharedButtonProps {
  theme?: 'Primary' | 'SecondaryLight' | 'SecondaryDark' | 'Discord';
}

export interface ButtonProps extends BaseButtonProps, SharedButtonProps {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonComponent(
  { children, theme = 'Primary', className = '', ...props },
  ref,
) {
  return (
    <BaseButton
      ref={ref}
      className={`${styles.root} ${styles['theme' + theme]} ${className}`}
      {...props}
    >
      <span className={`${styles.bgOverlay} ${styles.hover}`}></span>
      <span className={`${styles.bgOverlay} ${styles.pressed}`}></span>
      <span className={styles.content}>{children}</span>
      <span className={styles.highlight}></span>
    </BaseButton>
  );
});

export interface AnchorButtonProps extends BaseAnchorButtonProps, SharedButtonProps {}

export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  function AnchorButtonComponent({ children, theme = 'Primary', className = '', ...props }, ref) {
    return (
      <BaseAnchorButton
        ref={ref}
        className={`${styles.root} ${styles['theme' + theme]} ${className}`}
        {...props}
      >
        <span className={`${styles.bgOverlay} ${styles.hover}`}></span>
        <span className={`${styles.bgOverlay} ${styles.pressed}`}></span>
        <span className={styles.content}>{children}</span>
        <span className={styles.highlight}></span>
      </BaseAnchorButton>
    );
  },
);
