import '../styles/globals.css';
import type { FC } from 'react';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import PlausibleProvider from 'next-plausible';
import { StoreProvider } from '../providers/store-provider';
import { PageMemo } from '../components/page/page';
import { IS_RAY } from '../constants';
import { navSystem } from '../utils/nav-system';
import { suisseIntl, suisseIntlCondensed, suisseIntlMono } from '../fonts';
import { useApollo } from '../hooks/use-apollo';
import { useIsRay } from '../hooks/use-is-ray';
import { initSidePanelAnchors } from '../utils/side-panel-anchors';

const PLAUSIBLE_DOMAIN = process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN || '';

if (IS_RAY && typeof window !== 'undefined') {
  document.documentElement.classList.add('isRay');

  initSidePanelAnchors();

  navSystem.updateSettings({
    blurOnPointerInteraction: true,
    layers: ['#__next', '.nav-system-layer'],
  });
  navSystem.start();
}

export default function App({ Component, pageProps }: AppProps) {
  // Hack to get Vercel deployment working past v13.3.0. It failed due to a
  // type error: 'Component' cannot be used as a JSX component.
  const CustomComponent = Component as FC<any>;
  const apolloClient = useApollo(pageProps);
  const isRay = useIsRay();

  // Unrender the app if it's not being viewed in Ray Browser.
  if (!isRay) return null;

  return (
    <>
      <Head>
        <meta key="charset" charSet="utf8" />
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
        <meta key="theme-color" name="theme-color" content="#000000" />
        <link key="icon" rel="icon" type="image/svg+xml" href="/logo-square.svg" />
        <link key="mask-icon" rel="mask-icon" href="/logo-square.svg" color="#f95e14" />
        <link
          key="apple-touch-icon"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link key="manifest" rel="manifest" href="/site.webmanifest" />
      </Head>
      <style jsx global>{`
        :root {
          --g--font-suisse-intl: ${suisseIntl.style.fontFamily};
          --g--font-suisse-intl-condensed: ${suisseIntlCondensed.style.fontFamily};
          --g--font-suisse-intl-mono: ${suisseIntlMono.style.fontFamily};
        }
      `}</style>
      <StoreProvider>
        <PlausibleProvider domain={PLAUSIBLE_DOMAIN} enabled={!!PLAUSIBLE_DOMAIN}>
          <ApolloProvider client={apolloClient}>
            <PageMemo>
              <CustomComponent {...pageProps} />
            </PageMemo>
          </ApolloProvider>
        </PlausibleProvider>
      </StoreProvider>
    </>
  );
}
