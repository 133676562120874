import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_TARGET_GROUP_CLASS,
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
  ARROW_NAV_DIRECTION,
} from '../constants';
import { getDistanceFromPointToRect } from './get-distance-from-point-to-rect';
import { getDistanceFromRectToRect } from './get-distance-from-rect-to-rect';
import { getArrowNavRect } from './get-arrow-nav-rect';
import { isElementFocusable } from './is-element-focusable';
import { isElementVisible } from './is-element-visible';
import { Rectangle } from './types';

function isTargetInNavDirection(
  targetRect: Rectangle,
  sourceRect: Rectangle,
  direction?: ARROW_NAV_DIRECTION,
) {
  switch (direction) {
    case ARROW_NAV_DIRECTION.UP: {
      return targetRect.y + targetRect.height <= sourceRect.y;
    }
    case ARROW_NAV_DIRECTION.DOWN: {
      return targetRect.y >= sourceRect.y + sourceRect.height;
    }
    case ARROW_NAV_DIRECTION.LEFT: {
      return targetRect.x + targetRect.width <= sourceRect.x;
    }
    case ARROW_NAV_DIRECTION.RIGHT: {
      return targetRect.x >= sourceRect.x + sourceRect.width;
    }
    default: {
      return true;
    }
  }
}

export function focusClosestArrowNavTarget(
  source: HTMLElement | { x: number; y: number },
  layer: HTMLElement,
  direction?: ARROW_NAV_DIRECTION,
) {
  const isSourceElement = source instanceof HTMLElement;
  const sourceRect = getArrowNavRect(source, layer);
  const processedElements = new Set<HTMLElement>();

  let match: HTMLElement | undefined;
  let container: HTMLElement | undefined;

  // Add source element to the processed elements set, so that we don't
  // accidentally select it as the closest element.
  if (isSourceElement) {
    processedElements.add(source);
  }

  // Here we loop through all arrow nav containers starting from the
  // source element's closest container and going up until we find a closest
  // arrow navigable element. If we don't find any closest arrow navigable
  // elements within the container, we move to the next container and repeat the
  // process until we find a closest arrow navigable element or we reach the
  // body element.
  containerLoop: while (!match && container !== layer) {
    // Find the next container.
    if (container) {
      container =
        (container.parentElement?.closest(`.${ARROW_NAV_CONTAINER_CLASS}`) as HTMLElement | null) ||
        layer;
    } else {
      if (isSourceElement) {
        container =
          (source.closest(`.${ARROW_NAV_CONTAINER_CLASS}`) as HTMLElement | null) || layer;
      } else {
        container = layer;
      }
    }

    // Make sure we don't go outside of the layer. The container must be within
    // the layer.
    if (!layer.contains(container)) {
      container = layer;
    }

    // Add container to the processed elements set, so that we don't
    // accidentally select it as the closest arrow nav target or arrow nav
    // target group when we move on to the next container.
    processedElements.add(container);

    // Find all arrow navigable elements within the container.
    const targets = [...container.querySelectorAll(`.${ARROW_NAV_TARGET_CLASS}`)] as HTMLElement[];

    // Find all arrow nav target groups within the container.
    const targetGroups = [
      ...container.querySelectorAll(`.${ARROW_NAV_TARGET_GROUP_CLASS}`),
    ] as HTMLElement[];

    // Parse ignore settings from container.
    const ignoreValues = (container.getAttribute(ARROW_NAV_IGNORE_ATTRIBUTE) || '')
      .split(' ')
      .map((v) => v.trim())
      .filter(Boolean);

    // Check if this container should be ignored.
    let ignoreContainer = false;
    for (const ignore of ignoreValues) {
      if (ignoreContainer) break;
      switch (ignore) {
        case 'x': {
          if (direction === ARROW_NAV_DIRECTION.LEFT || direction === ARROW_NAV_DIRECTION.RIGHT)
            ignoreContainer = true;
          break;
        }
        case 'y': {
          if (direction === ARROW_NAV_DIRECTION.UP || direction === ARROW_NAV_DIRECTION.DOWN)
            ignoreContainer = true;
          break;
        }
        case 'left': {
          if (direction === ARROW_NAV_DIRECTION.LEFT) ignoreContainer = true;
          break;
        }
        case 'right': {
          if (direction === ARROW_NAV_DIRECTION.RIGHT) ignoreContainer = true;
          break;
        }
        case 'up': {
          if (direction === ARROW_NAV_DIRECTION.UP) ignoreContainer = true;
          break;
        }
        case 'down': {
          if (direction === ARROW_NAV_DIRECTION.DOWN) ignoreContainer = true;
          break;
        }
      }
    }

    // If the container should be ignored let's continue to the next container.
    if (ignoreContainer) {
      // Add all the targets and target groups within the container to the
      // processed elements set so that we don't process them again.
      [...targets, ...targetGroups].forEach((target) => processedElements.add(target));
      continue containerLoop;
    }

    // Valid targets are focusable arrow nav targets or target groups that are
    // in the direction of the key press. Let's collect these while we loop
    // through all the targets so we can use them later if we need to find
    // closest elements within the target groups.
    const validTargets: { target: HTMLElement; distance: number }[] = [];

    // Loop through all arrow nav targets and target groups.
    let closestDistance = Infinity;
    for (const target of targets) {
      // Don't process the same element twice.
      if (processedElements.has(target)) continue;

      // Make sure the target is focusable.
      if (!isElementFocusable(target)) continue;

      // Get the target client rect.
      const targetRect = getArrowNavRect(target, layer);

      // Ignore elements that are not in the direction of the key press.
      if (!isTargetInNavDirection(targetRect, sourceRect, direction)) {
        continue;
      }

      // Compute the distance from the source element to the target element.
      let distance = 0;
      if (isSourceElement) {
        distance = getDistanceFromRectToRect(sourceRect, targetRect);
      } else {
        distance = getDistanceFromPointToRect(source, targetRect);
      }

      // Add target to the valid targets collection.
      validTargets.push({ target, distance });

      // If it's closer than the previous closest element, let's use it as the
      // new match.
      if (distance < closestDistance) {
        closestDistance = distance;
        match = target;
      }
    }

    // If we found a match let's see if there are any closer target groups
    // that we should consider. We'll fallback to the closest original match
    // if we don't find any closer target groups and suitable targets within
    // those groups.
    if (match) {
      // First of all let's get all the groups that are in the direction of
      // the key press and are closer to the source element than the original
      // match.
      let validGroups: { group: HTMLElement; distance: number }[] = [];
      for (const group of targetGroups) {
        if (processedElements.has(group)) continue;

        // Ignore hidden groups.
        if (!isElementVisible(group)) continue;

        // Get the group client rect.
        const groupRect = getArrowNavRect(group, layer);

        // Make sure the group is in the direction of the key press.
        if (!isTargetInNavDirection(groupRect, sourceRect, direction)) {
          continue;
        }

        let distance = 0;
        if (isSourceElement) {
          distance = getDistanceFromRectToRect(sourceRect, groupRect);
        } else {
          distance = getDistanceFromPointToRect(source, groupRect);
        }

        if (distance < closestDistance) {
          validGroups.push({ group, distance });
        }
      }

      // Sort the groups by distance, from smallest to largest.
      validGroups = validGroups.sort((a, b) => a.distance - b.distance);

      // Loop through the valid groups, find the targets within the group
      // and use the closest target as the new match.
      for (const groupData of validGroups) {
        const { group } = groupData;

        const closestGroupTarget = validTargets
          .filter(({ target }) => group.contains(target))
          .sort((a, b) => a.distance - b.distance)[0];

        if (closestGroupTarget) {
          match = closestGroupTarget.target;
          closestDistance = closestGroupTarget.distance;
          break;
        }
      }
    }

    // Add all the targets and target groups within the container to the
    // processed elements set so that we don't process them again.
    [...targets, ...targetGroups].forEach((target) => processedElements.add(target));

    // If we didn't find any match, let's check if the container is containing
    // nav in the direction of the key press (i.e. prevent the nav keypress
    // reaching outer containers).
    if (!match) {
      const containValues = (container.getAttribute(ARROW_NAV_CONTAIN_ATTRIBUTE) || '')
        .split(' ')
        .map((v) => v.trim())
        .filter(Boolean);

      for (const contain of containValues) {
        switch (contain) {
          case 'x': {
            if (direction === ARROW_NAV_DIRECTION.LEFT || direction === ARROW_NAV_DIRECTION.RIGHT)
              break containerLoop;
            break;
          }
          case 'y': {
            if (direction === ARROW_NAV_DIRECTION.UP || direction === ARROW_NAV_DIRECTION.DOWN)
              break containerLoop;
            break;
          }
          case 'left': {
            if (direction === ARROW_NAV_DIRECTION.LEFT) break containerLoop;
            break;
          }
          case 'right': {
            if (direction === ARROW_NAV_DIRECTION.RIGHT) break containerLoop;
            break;
          }
          case 'up': {
            if (direction === ARROW_NAV_DIRECTION.UP) break containerLoop;
            break;
          }
          case 'down': {
            if (direction === ARROW_NAV_DIRECTION.DOWN) break containerLoop;
            break;
          }
        }
      }
    }
  }

  // If we found match let's focus it (without scrolling to it automatically).
  if (match) {
    match.focus({ preventScroll: true });
    return true;
  } else {
    return false;
  }
}
