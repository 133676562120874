import { useStore } from '../providers/store-provider';
import { useRootClass } from './use-root-class';

// Toggle "noRecentlyPlayedItems" class on the document element based on
// whether there are recently played games or not. Note that we default to
// assuming that there are recently played items if the recently played data
// has not loaded yet to avoid a layout shift. In 99% of the cases this will
// be the correct default. The layout will shift only for people who have no
// recently played items.
export function useRecentlyPlayedRootClass() {
  const recentlyPlayedItems = useStore((state) => state.recentlyPlayedItems);
  const hasRecentlyPlayedItems = !recentlyPlayedItems || recentlyPlayedItems.length;
  useRootClass('noRecentlyPlayedItems', !hasRecentlyPlayedItems);
}
