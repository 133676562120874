import { forwardRef, useMemo, ComponentProps } from 'react';
import type { UrlObject } from 'url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { STICKY_SEARCH_PARAMS } from '../../constants';

const useStickyParams = (href: string | UrlObject): string | UrlObject => {
  const router = useRouter();

  return useMemo(() => {
    const appendParamsToString = (url: string): string => {
      // Check if href is a relative path.
      const isRelative = url.startsWith('/') || url.startsWith('#') || url.startsWith('?');
      if (!isRelative) return url;

      // Split the href into path and query string.
      const [path, queryString] = url.split('?');
      const urlParams = new URLSearchParams(queryString || '');

      // Append sticky params from router.query if they're not already present.
      STICKY_SEARCH_PARAMS.forEach((param) => {
        if (param in router.query && !urlParams.has(param)) {
          const value = router.query[param] || '';
          urlParams.set(param, Array.isArray(value) ? value[0] || '' : value);
        }
      });

      // Reconstruct the href with the updated query parameters.
      const newQuery = urlParams.toString();
      return newQuery ? `${path}?${newQuery}` : path || '';
    };

    const appendParamsToUrlObject = (urlObj: UrlObject): UrlObject => {
      // Check if href is a relative path
      const isRelative = !urlObj.protocol && !urlObj.host && !urlObj.hostname;
      if (!isRelative) return urlObj;

      const urlParams = new URLSearchParams((urlObj.query as any) || {});

      // Append sticky params from router.query if they're not already present.
      STICKY_SEARCH_PARAMS.forEach((param) => {
        if (param in router.query && !urlParams.has(param)) {
          const value = router.query[param] || '';
          urlParams.set(param, Array.isArray(value) ? value[0] || '' : value);
        }
      });

      // Reconstruct the href with the updated query parameters.
      return {
        ...urlObj,
        query: Object.fromEntries(urlParams.entries()),
      };
    };

    if (typeof href === 'string') {
      return appendParamsToString(href);
    } else {
      return appendParamsToUrlObject(href);
    }
  }, [href, router.query]);
};

export type InternalLinkProps = Omit<ComponentProps<typeof Link>, 'ref'>;

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(
  function InternalLinkComponent(
    { children, href, scroll = false, draggable = false, ...restProps },
    ref,
  ) {
    const updatedHref = useStickyParams(href);
    return (
      <Link ref={ref} href={updatedHref} scroll={scroll} draggable={draggable} {...restProps}>
        {children}
      </Link>
    );
  },
);
