import { getScrollableAncestors } from './get-scrollable-ancestors';

// A function that takes an element and scrolls it into view if it's not fully
// visible within the viewport.
export function scrollIntoView(
  elem: Element,
  options?: {
    axis?: 'x' | 'y' | 'xy';
    edgePadding: {
      left?: number;
      right?: number;
      top?: number;
      bottom?: number;
    };
  },
) {
  const {
    axis = 'xy',
    edgePadding: {
      left: edgePaddingLeft = 0,
      right: edgePaddingRight = 0,
      top: edgePaddingTop = 0,
      bottom: edgePaddingBottom = 0,
    } = {},
  } = options || {};
  const elemRect = elem.getBoundingClientRect();

  // Calculate horizontal scroll offset.
  if (axis === 'xy' || axis === 'x') {
    let xScrollOffset = 0;
    const [xScrollAncestor] = getScrollableAncestors(elem, {
      axis: 'x',
      firstMatchOnly: true,
    });

    if (
      xScrollAncestor instanceof Window ||
      xScrollAncestor === document.body ||
      xScrollAncestor === document.documentElement
    ) {
      const clientLeft = 0 + edgePaddingLeft;
      const clientRight = window.innerWidth - edgePaddingRight;
      // If the element is wider than the viewport. This is a special case
      // because we want to scroll to the left edge of the element if it's
      // clipped by the right edge of the viewport, and to the right edge of
      // the element if it's clipped by the left edge of the viewport.
      if (elemRect.width > clientRight - clientLeft) {
        xScrollOffset =
          elemRect.left - clientLeft > clientRight - elemRect.right
            ? elemRect.left - clientLeft
            : elemRect.right - clientRight;
      }
      // If the element is fully/partially clipped by the left edge.
      else if (elemRect.left < clientLeft) {
        xScrollOffset = elemRect.left - clientLeft;
      }
      // If the element is fully/partially clipped by the right edge.
      else if (elemRect.right > clientRight) {
        xScrollOffset = elemRect.right - clientRight;
      }

      // Scroll horizontally.
      if (xScrollOffset) {
        window.scrollBy({ left: xScrollOffset, behavior: 'smooth' });
      }
    } else if (xScrollAncestor instanceof Element) {
      const scrollerRect = xScrollAncestor.getBoundingClientRect();
      const clientLeft = Math.max(scrollerRect.left, 0) + edgePaddingLeft;
      const clientRight = Math.min(scrollerRect.right, window.innerWidth) - edgePaddingRight;

      // If the element is wider than the viewport. This is a special case
      // because we want to scroll to the left edge of the element if it's
      // clipped by the right edge of the viewport, and to the right edge of
      // the element if it's clipped by the left edge of the viewport.
      if (elemRect.width > clientRight - clientLeft) {
        xScrollOffset =
          elemRect.left - clientLeft > clientRight - elemRect.right
            ? elemRect.left - clientLeft
            : elemRect.right - clientRight;
        xScrollOffset = 0;
      }
      // If the element is fully/partially clipped by the left edge.
      else if (elemRect.left < clientLeft) {
        xScrollOffset = elemRect.left - clientLeft;
      }
      // If the element is fully/partially clipped by the right edge.
      else if (elemRect.right > clientRight) {
        xScrollOffset = elemRect.right - clientRight;
      }

      // Scroll horizontally.
      if (xScrollOffset) {
        xScrollAncestor?.scrollBy({ left: xScrollOffset, behavior: 'smooth' });
      }
    }
  }

  // Calculate vertical scroll offset.
  if (axis === 'xy' || axis === 'y') {
    let yScrollOffset = 0;
    const [yScrollAncestor] = getScrollableAncestors(elem, {
      axis: 'y',
      firstMatchOnly: true,
    });

    if (
      yScrollAncestor instanceof Window ||
      yScrollAncestor === document.body ||
      yScrollAncestor === document.documentElement
    ) {
      const clientTop = 0 + edgePaddingTop;
      const clientBottom = window.innerHeight - edgePaddingBottom;

      // If the element is taller than the viewport. This is a special case
      // because we want to scroll to the top edge of the element if it's
      // clipped by the bottom edge of the viewport, and to the bottom edge of
      // the element if it's clipped by the top edge of the viewport.
      if (elemRect.height > clientBottom - clientTop) {
        yScrollOffset =
          elemRect.top - clientTop > clientBottom - elemRect.bottom
            ? elemRect.top - clientTop
            : elemRect.bottom - clientBottom;
      }
      // If the element is fully/partially clipped by the top edge.
      else if (elemRect.top < clientTop) {
        yScrollOffset = elemRect.top - clientTop;
      }
      // If the element is fully/partially clipped by the bottom edge.
      else if (elemRect.bottom > clientBottom) {
        yScrollOffset = elemRect.bottom - clientBottom;
      }

      // Scroll vertically.
      if (yScrollOffset) {
        window.scrollBy({ top: yScrollOffset, behavior: 'smooth' });
      }
    } else if (yScrollAncestor instanceof Element) {
      const scrollerRect = yScrollAncestor.getBoundingClientRect();
      const clientTop = Math.max(scrollerRect.top, 0) + edgePaddingTop;
      const clientBottom = Math.min(scrollerRect.bottom, window.innerHeight) - edgePaddingBottom;

      // If the element is taller than the viewport. This is a special case
      // because we want to scroll to the top edge of the element if it's
      // clipped by the bottom edge of the viewport, and to the bottom edge of
      // the element if it's clipped by the top edge of the viewport.
      if (elemRect.height > clientBottom - clientTop) {
        yScrollOffset =
          elemRect.top - clientTop > clientBottom - elemRect.bottom
            ? elemRect.top - clientTop
            : elemRect.bottom - clientBottom;
      }
      // If the element is fully/partially clipped by the top edge.
      else if (elemRect.top < clientTop) {
        yScrollOffset = elemRect.top - clientTop;
      }
      // If the element is fully/partially clipped by the bottom edge.
      else if (elemRect.bottom > clientBottom) {
        yScrollOffset = elemRect.bottom - clientBottom;
      }

      // Scroll horizontally.
      if (yScrollOffset) {
        yScrollAncestor?.scrollBy({ top: yScrollOffset, behavior: 'smooth' });
      }
    }
  }
}
