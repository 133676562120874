import { Point, Rectangle } from './types';

export function getDistanceFromPointToRect(point: Point, rectangle: Rectangle): number {
  const { x, y } = point;
  const { x: rectX, y: rectY, width, height } = rectangle;

  // Calculate the closest x-coordinate within the rectangle
  let closestX: number;
  if (x < rectX) {
    closestX = rectX;
  } else if (x > rectX + width) {
    closestX = rectX + width;
  } else {
    closestX = x;
  }

  // Calculate the closest y-coordinate within the rectangle
  let closestY: number;
  if (y < rectY) {
    closestY = rectY;
  } else if (y > rectY + height) {
    closestY = rectY + height;
  } else {
    closestY = y;
  }

  // Calculate the distance between the point and the closest point within the rectangle
  const dx = x - closestX;
  const dy = y - closestY;
  const distance = Math.sqrt(dx * dx + dy * dy);

  return distance;
}
