import { getLayerElements } from './get-layer-elements';

export function getClosestLayerElement(
  element: Element | null,
  layerElements = getLayerElements(),
) {
  while (element) {
    if (layerElements.includes(element as HTMLElement)) {
      return element as HTMLElement;
    }
    element = element.parentElement;
  }
  return null;
}
