import { ARROW_NAV_STICKY_CLASS, ARROW_NAV_DIRECTION } from '../constants';
import { settings } from '../settings';
import { getScrollableAncestors } from './get-scrollable-ancestors';
import { parseFocusScrollPadding } from './parse-focus-scroll-padding';

// If the user is navigating within a sticky container we want to scroll the
// window to the closest sticky edge instead of focusing the closest arrow
// navigable element.
export function handleArrowNavStickyScroll(
  sourceElement: HTMLElement,
  layer: HTMLElement,
  direction?: ARROW_NAV_DIRECTION,
) {
  // Make sure sticky scroll is enabled. The interval should be a number between
  // 0 and 1. It defines how much of the viewport height we want to scroll
  // towards the sticky edge.
  if (!settings.stickyScrollInterval) return false;

  // Sticky scroll works only vertically for now, so let's ignore horizontal
  // nav.
  const isUpKey = direction === ARROW_NAV_DIRECTION.UP;
  const isDownKey = direction === ARROW_NAV_DIRECTION.DOWN;
  if (!isUpKey && !isDownKey) return false;

  // Make sure the we have a sticky container and that the sticky container is
  // within the container.
  const stickyContainer = sourceElement.closest(`.${ARROW_NAV_STICKY_CLASS}`);
  if (!stickyContainer || !layer.contains(stickyContainer)) return false;

  // Find the closest scrollable ancestor that is scrollable vertically.
  let yScrollAncestor = getScrollableAncestors(stickyContainer, {
    axis: 'y',
    firstMatchOnly: true,
  })[0];

  // Make sure we have a scrollable ancestor.
  if (!yScrollAncestor) return false;

  // Normalize body and root element to window, because setting scroll on them
  // (most of the time) equals to the scrolling being on the window.
  if (yScrollAncestor === document.body || yScrollAncestor === document.documentElement) {
    yScrollAncestor = window;
  }

  // Compute the client top/bottom and client height of the scrollable ancestor.
  let clientTop = 0;
  let clientBottom = 0;
  let clientHeight = 0;
  const edgePadding = parseFocusScrollPadding(stickyContainer);
  if (yScrollAncestor instanceof Window) {
    clientTop = edgePadding.top;
    clientBottom = window.innerHeight - edgePadding.bottom;
    clientHeight = clientBottom - clientTop;
  } else {
    const scrollerRect = yScrollAncestor.getBoundingClientRect();
    clientTop = Math.max(scrollerRect.top, 0) + edgePadding.top;
    clientBottom = Math.min(scrollerRect.bottom, window.innerHeight) - edgePadding.bottom;
    clientHeight = clientBottom - clientTop;
  }

  const stickyRect = stickyContainer.getBoundingClientRect();

  // If the sticky container is smaller than the viewport height, we don't need
  // to do anything.
  if (stickyRect.height <= clientHeight) {
    return false;
  }

  // Finally, let's scroll the scrollable to the direction of the up/down arrow
  // key if the sticky container top/bottom is not already fully visible.
  if (
    (isUpKey && Math.round(stickyRect.top) < Math.round(clientTop)) ||
    (isDownKey && Math.round(stickyRect.bottom) > Math.round(clientBottom))
  ) {
    if (isUpKey) {
      yScrollAncestor.scrollBy({
        top: Math.max(stickyRect.top - clientTop, clientHeight * -settings.stickyScrollInterval),
        behavior: 'smooth',
      });
    } else {
      yScrollAncestor.scrollBy({
        top: Math.min(
          stickyRect.bottom - clientBottom,
          clientHeight * settings.stickyScrollInterval,
        ),
        behavior: 'smooth',
      });
    }
    return true;
  }

  return false;
}
