import { isElementVisible } from './is-element-visible';

export function isElementFocusable(
  element:
    | Element
    | HTMLElement
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
    | HTMLButtonElement,
) {
  // Make sure there's a tabIndex property in the element.
  if (!('tabIndex' in element)) return false;

  // Make sure tab index is zero or greater.
  if (element.tabIndex < 0) return false;

  // Make sure the element is not disabled.
  if ('disabled' in element && element.disabled) return false;

  return isElementVisible(element);
}
