import { useEffect } from 'react';
import { useStore } from '../providers/store-provider';

// This hook is responsible for setting up the store degug flags on page load
// on the client side.
export function useSyncDebugFlags() {
  const setDebug = useStore((state) => state.setDebug);

  // Parse URL search params and set the debug flags. Note that we intentionally
  // don't use the useSearchParams hook here, because it is not working as
  // expected: it triggers extra re-renders and it falsely sets the debug flags
  // to incorrect values for a brief moment. Additionally, since we know that
  // the debug flags are only set once on page load, we can safely parse the
  // search params once and set the flags in the global store. This way, we
  // avoid unnecessary re-renders and don't have to worry about the search
  // params changing/vanishing during client-side navigation.
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const { searchParams } = new URL(window.location.href);
    setDebug({
      disableVideos: searchParams.get('disableVideos') !== null,
    });
  }, [setDebug]);
}
