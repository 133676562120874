import { getStyle } from './get-style';

export function isElementVisible(element: Element | HTMLElement) {
  // Check if the element is hidden via CSS visibility prop.
  if (getStyle(element).visibility === 'hidden') {
    return false;
  }

  // Use getClientRects to check if the element (or any ancestor) has
  // "display:none".
  if (element.getClientRects().length === 0) {
    return false;
  }

  return true;
}
