import { memo } from 'react';
import { InternalLink } from '../internal-link/internal-link';
import { RayLogoIcon } from '../svg-icons/ray-logo-icon';
import {
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
} from '../../utils/nav-system';
import { SIDE_PANEL_ANCHOR_CLASS } from '../../constants';
import styles from './footer.module.css';

export function Footer() {
  return (
    <footer
      className={`${styles.root} ${ARROW_NAV_CONTAINER_CLASS}`}
      {...{
        [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
      }}
    >
      <div className={styles.content}>
        <RayLogoIcon className={styles.logo} />
        <ul className={styles.links}>
          <li>
            <InternalLink
              className={`${ARROW_NAV_TARGET_CLASS} ${SIDE_PANEL_ANCHOR_CLASS}`}
              href="https://www.raybrowser.com/tos"
              target="_blank"
            >
              Terms Of Service
            </InternalLink>
          </li>
          <li>
            <InternalLink
              className={`${ARROW_NAV_TARGET_CLASS} ${SIDE_PANEL_ANCHOR_CLASS}`}
              href="https://www.raybrowser.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </InternalLink>
          </li>
          <li>
            <InternalLink
              className={`${ARROW_NAV_TARGET_CLASS} ${SIDE_PANEL_ANCHOR_CLASS}`}
              href="https://www.raybrowser.com/cookies"
              target="_blank"
            >
              Cookie Policy
            </InternalLink>
          </li>
        </ul>
        <p className={styles.copyright}>© 2024 Ray Browser Ltd</p>
      </div>
      <div className={styles.logoStampContainer}>
        <RayLogoIcon className={styles.logoStamp} />
      </div>
    </footer>
  );
}

export const FooterMemo = memo(Footer);
