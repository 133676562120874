import { emitter } from '../emitter';
import { settings } from '../settings';
import { getFocusableAncestor } from '../utils/get-focusable-ancestor';
import { getClosestFocusableElementFromPoint } from '../utils/get-closest-focusable-element-from-point';
import { getClosestLayerElement } from '../utils/get-closest-layer-element';
import { getPointerPosition } from './pointer-position';

export type NavDevice = 'mouse' | 'pen' | 'touch' | 'keyboard';

let isTracking = false;
let navDevice: NavDevice = 'keyboard';
let previousNavDevice: NavDevice = 'keyboard';

export function setNavDevice(nextNavDevice: NavDevice) {
  previousNavDevice = navDevice;
  if (navDevice === nextNavDevice) return;
  navDevice = nextNavDevice;
  // We set the data-navigation-device attribute asynchronously (in a microtask)
  // to make sure that any CSS selectors that use the attribute do not get
  // applied until after all the keydown event listeners and their synchronous
  // side-effects have been executed. If we set the attribute synchronously here
  // and there would be a CSS selector that uses the attribute to set an
  // element's "pointer-events" to "none", it would mean that the element would
  // be completely ignored by document.elementFromPoint() method and we would
  // not be able to find the closest focusable element to the pointer position.
  const _previousNavDevice = previousNavDevice;
  window.queueMicrotask(() => {
    if (isTracking) {
      document.documentElement.setAttribute('data-nav-device', nextNavDevice);
      emitter.emit('nav-device-changed', nextNavDevice, _previousNavDevice);
    }
  });
}

// Track the nav device globally.
const onPointerInteraction = (e: PointerEvent) => {
  // Update nav device.
  setNavDevice(e.pointerType as 'mouse' | 'pen' | 'touch');

  // When using pointer devices, we want to rely solely on :hover states for
  // focus indicators so let's blur the active element. This fixes the scenario
  // where a user focuses an element with a keyboard and then hovers over
  // another element with a pointer device. Without this, the focus/hover
  // indicator would be shown for both elements.
  if (settings.blurOnPointerInteraction) {
    const { activeElement } = document;
    if (
      activeElement &&
      activeElement !== document.body &&
      activeElement instanceof HTMLElement &&
      activeElement.contentEditable !== 'true' &&
      !(activeElement instanceof HTMLInputElement) &&
      !(activeElement instanceof HTMLTextAreaElement) &&
      !(activeElement instanceof HTMLSelectElement)
    ) {
      activeElement.blur();
    }
  }
};

const onKeydown = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'Tab': {
      // Update nav device.
      setNavDevice('keyboard');

      // If the nav device changed from non-keyboard to keyboard, we want to
      // focus the closest focusable element to the pointer position...

      // Make sure nav device changed from non-keyboard to keyboard.
      if (previousNavDevice === 'keyboard') break;

      // Make sure there is no focused element.
      const { activeElement } = document;
      if (activeElement && activeElement !== document.body) break;

      // Try to find the closest focusable element to the pointer position.
      // Let's first try to get a focusable element that the pointer is
      // currently hovering over. And if that fails, let's try to find the
      // visually closest focusable element to the pointer position.
      const pointerClientPosition = getPointerPosition();
      const elementFromPoint = document.elementFromPoint(
        pointerClientPosition.x,
        pointerClientPosition.y,
      );
      if (!elementFromPoint) break;

      // Get the layer element.
      const layerElement = getClosestLayerElement(elementFromPoint);
      if (!layerElement) break;

      // Get the focusable element under the pointer (if any).
      const hoveredFocusable = getFocusableAncestor(elementFromPoint, layerElement);

      // Let's use the hovered focusable element if there is one. In case there
      // isn't let's try to find the closest focusable visually.
      const closestFocusable =
        hoveredFocusable ||
        getClosestFocusableElementFromPoint(pointerClientPosition, layerElement);

      // Focus the closest focusable element (without scrolling to it).
      if (closestFocusable) {
        e.preventDefault();
        closestFocusable.focus({ preventScroll: true });
      }

      break;
    }
    case 'Enter': {
      // If pointer is currently hovering a focusable element and Enter is
      // pressed, we want to click it manually. But before we can do that
      // there's a bunch of things to check...

      // Make sure the option is enabled.
      if (!settings.clickHoveredFocusableOnEnter) break;

      // Make sure nav device changed from non-keyboard to keyboard.
      if (previousNavDevice === 'keyboard') break;

      // Make sure there is no focused element.
      const { activeElement } = document;
      if (activeElement && activeElement !== document.body) break;

      // Try to find the closest focusable element to the pointer position.
      // Let's first try to get a focusable element that the pointer is
      // currently hovering over. And if that fails, let's try to find the
      // visually closest focusable element to the pointer position.
      const pointerClientPosition = getPointerPosition();
      const elementFromPoint = document.elementFromPoint(
        pointerClientPosition.x,
        pointerClientPosition.y,
      );
      if (!elementFromPoint) break;

      // Get the layer element.
      const layerElement = getClosestLayerElement(elementFromPoint);
      if (!layerElement) break;

      // Get the focusable element under the pointer (if any).
      const hoveredFocusable = getFocusableAncestor(elementFromPoint, layerElement);

      if (hoveredFocusable) {
        e.preventDefault();
        hoveredFocusable.click();
      }
      break;
    }
    default: {
      return;
    }
  }
};

export function start() {
  if (isTracking || typeof window === 'undefined') return;
  isTracking = true;
  window.addEventListener('pointermove', onPointerInteraction, { capture: true });
  window.addEventListener('pointerup', onPointerInteraction, { capture: true });
  window.addEventListener('pointerdown', onPointerInteraction, { capture: true });
  window.addEventListener('keydown', onKeydown, { capture: true });
  document.documentElement.setAttribute('data-nav-device', navDevice);
  emitter.emit('nav-device-changed', navDevice, previousNavDevice);
}

export function stop() {
  if (!isTracking || typeof window === 'undefined') return;
  isTracking = false;
  window.removeEventListener('pointermove', onPointerInteraction, { capture: true });
  window.removeEventListener('pointerup', onPointerInteraction, { capture: true });
  window.removeEventListener('pointerdown', onPointerInteraction, { capture: true });
  window.removeEventListener('keydown', onKeydown, { capture: true });
  document.documentElement.removeAttribute('data-nav-device');
}

export function getNavDevice() {
  return navDevice;
}

export function getPreviousNavDevice() {
  return previousNavDevice;
}
