import type { GetRecentlyPlayedItemsQuery } from '../.gql/graphql';
import { createStore as createZustandStore } from 'zustand/vanilla';
import { subscribeWithSelector } from 'zustand/middleware';
import { validate as uuidValidate } from 'uuid';
import { STORAGE_KEY_RECENTLY_PLAYED, MAX_RECENTLY_PLAYED_ITEMS } from '../constants';

export type StoreData = {
  isMediaMuted: boolean;
  isModalOpen: boolean;
  activeCardId: string | null;
  recentlyPlayedIds: string[] | null;
  recentlyPlayedItems: GetRecentlyPlayedItemsQuery['items'] | null;
  debug: {
    disableVideos: boolean | undefined;
  };
};

export type StoreActions = {
  setMediaMuted: (isMediaMuted: boolean) => void;
  setModalOpen: (isModalOpen: boolean) => void;
  setActiveCardId: (id: string | null) => void;
  setRecentlyPlayedItems: (items: GetRecentlyPlayedItemsQuery['items'] | null) => void;
  setRecentlyPlayedIds: (ids: string[] | null) => void;
  addRecentlyPlayedId: (id: string) => void;
  setDebug: (debug: Partial<StoreData['debug']>) => void;
};

export type StoreState = StoreData & StoreActions;

export const defaultInitData: StoreData = {
  isMediaMuted: false,
  isModalOpen: false,
  activeCardId: null,
  recentlyPlayedIds: null,
  recentlyPlayedItems: null,
  debug: {
    disableVideos: undefined,
  },
};

export const createStore = (initData: StoreData = defaultInitData) => {
  let recentlyPlayedAddTimeout: number = -1;
  return createZustandStore<StoreState>()(
    subscribeWithSelector((set) => ({
      ...initData,
      setMediaMuted: (isMediaMuted) => {
        set({ isMediaMuted });
      },
      setModalOpen: (isModalOpen) => {
        set({ isModalOpen });
      },
      setActiveCardId: (activeCardId) => {
        set({ activeCardId });
      },
      setRecentlyPlayedItems: (recentlyPlayedItems) => {
        set({ recentlyPlayedItems });
      },
      setRecentlyPlayedIds: (recentlyPlayedIds) => {
        set({ recentlyPlayedIds });
      },
      addRecentlyPlayedId: (id) => {
        if (!uuidValidate(id)) {
          throw new Error(`Invalid ID: ${id}`);
        }

        window.clearTimeout(recentlyPlayedAddTimeout);

        // Parse the recently played list from local storage.
        let recentlyPlayedIds: string[] = JSON.parse(
          localStorage.getItem(STORAGE_KEY_RECENTLY_PLAYED) || '[]',
        );

        // Remove the ID if it already exists in the list.
        recentlyPlayedIds = recentlyPlayedIds.filter((i) => i !== id);

        // Add the ID to the beginning of the list.
        recentlyPlayedIds.unshift(id);

        // Keep only the first X items.
        recentlyPlayedIds = recentlyPlayedIds.slice(0, MAX_RECENTLY_PLAYED_ITEMS);

        // Update the local storage immediately.
        localStorage.setItem(STORAGE_KEY_RECENTLY_PLAYED, JSON.stringify(recentlyPlayedIds));

        // However, update the state after a short delay to prevent the UI from
        // re-rendering immediately. If `addRecentlyPlayedId` is called in an
        // anchor tag's `onClick` handler (e.g. recently played card) the UI might
        // re-render before the click event is fully processed, causing the
        // browser to navigate to a wrong URL.
        recentlyPlayedAddTimeout = window.setTimeout(() => {
          set({ recentlyPlayedIds });
        }, 0);
      },
      setDebug: (debug) => {
        set((state) => {
          return {
            debug: {
              ...state.debug,
              ...debug,
            },
          };
        });
      },
    })),
  );
};
