import { forwardRef, useMemo, memo } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import { BulletDiamondIcon } from '../svg-icons/bullet-diamond';
import styles from './markdown.module.css';

export const Markdown = forwardRef<HTMLDivElement, Options>(function Markdown(
  { children, className = '', skipHtml = true, components, ...restProps },
  ref,
) {
  const componentsMemo: typeof components = useMemo(() => {
    return {
      h1: ({ node, ...props }) => <h3 {...props} />,
      h2: ({ node, ...props }) => <h4 {...props} />,
      h3: ({ node, ...props }) => <h5 {...props} />,
      h4: ({ node, ...props }) => <h6 {...props} />,
      h5: ({ node, ...props }) => <h6 {...props} />,
      h6: ({ node, ...props }) => <h6 {...props} />,
      li: ({ node, children, ...props }) => (
        <li {...props}>
          <span className={styles.liMarker}>
            <BulletDiamondIcon />
          </span>
          <span className={styles.liContent}>{children}</span>
        </li>
      ),
      ...components,
    };
  }, [components]);

  return (
    <div ref={ref} className={`${styles.root} ${className}`}>
      <ReactMarkdown skipHtml={skipHtml} components={componentsMemo} {...restProps}>
        {children}
      </ReactMarkdown>
    </div>
  );
});

export const MarkdownMemo = memo(Markdown);
