import type { GetRecentlyPlayedItemsQuery } from '../.gql/graphql';
import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useStore } from '../providers/store-provider';
import { GetRecentlyPlayedItems } from '../graphql-queries';
import { validate as uuidValidate } from 'uuid';
import { STORAGE_KEY_RECENTLY_PLAYED } from '../constants';

// Tracks the recently played item ids in the store and fetches the item data
// from the backend whenever the ids change, and stores the data in the store.
export function useSyncRecentlyPlayed() {
  const apolloClient = useApolloClient();
  const recentlyPlayedIds = useStore((state) => state.recentlyPlayedIds);
  const setRecentlyPlayedIds = useStore((state) => state.setRecentlyPlayedIds);
  const setRecentlyPlayedItems = useStore((state) => state.setRecentlyPlayedItems);

  // Load recently played ids from local storage on mount.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const recentlyPlayedIdsRaw = JSON.parse(
      localStorage.getItem(STORAGE_KEY_RECENTLY_PLAYED) || '[]',
    ) as unknown;

    if (Array.isArray(recentlyPlayedIdsRaw)) {
      const recentlyPlayedIds: string[] = recentlyPlayedIdsRaw.filter((id) => uuidValidate(id));
      setRecentlyPlayedIds(recentlyPlayedIds);
    } else {
      setRecentlyPlayedIds([]);
    }
  }, [setRecentlyPlayedIds]);

  // Whenever the list of recently played ids changes, update the recently
  // played slides.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    // If the recently played item ids are not yet loaded from the
    // local storage, do nothing.
    if (!recentlyPlayedIds) return;

    // If the recently played item ids have loaded but there are no items,
    // set the slides to an empty array.
    if (!recentlyPlayedIds.length) {
      setRecentlyPlayedItems([]);
      return;
    }

    let didUnmount = false;

    apolloClient
      .query({
        query: GetRecentlyPlayedItems,
        variables: { itemIds: recentlyPlayedIds },
      })
      .then(({ data }) => {
        if (didUnmount) return;
        const orderedItems = recentlyPlayedIds
          .map((id) => data.items?.find((slide) => slide.id === id))
          .filter(Boolean) as GetRecentlyPlayedItemsQuery['items'];
        setRecentlyPlayedItems(orderedItems);
      })
      .catch((e) => {
        if (didUnmount) return;
        setRecentlyPlayedItems([]);
        // TODO: Handle error properly (e.g. send to Sentry).
        console.error(`Failed to prefetch recently played data for items: ${recentlyPlayedIds}`);
        console.error(e);
      });
  }, [apolloClient, recentlyPlayedIds, setRecentlyPlayedItems]);
}
