import { FOCUS_SCROLL_PADDING_CSS_PROPERTY } from '../constants';
import { getStyle } from './get-style';

function parsePaddingString(paddingString: string) {
  if (paddingString) {
    const paddingValues = paddingString.split(' ').map((value) => parseFloat(value) || 0);

    switch (paddingValues.length) {
      case 1: {
        return {
          left: paddingValues[0]!,
          right: paddingValues[0]!,
          top: paddingValues[0]!,
          bottom: paddingValues[0]!,
        };
      }
      case 2: {
        return {
          left: paddingValues[1]!,
          right: paddingValues[1]!,
          top: paddingValues[0]!,
          bottom: paddingValues[0]!,
        };
      }
      case 3: {
        return {
          left: paddingValues[1]!,
          right: paddingValues[1]!,
          top: paddingValues[0]!,
          bottom: paddingValues[2]!,
        };
      }
      case 4: {
        return {
          left: paddingValues[3]!,
          right: paddingValues[1]!,
          top: paddingValues[0]!,
          bottom: paddingValues[2]!,
        };
      }
    }
  }

  return null;
}

export function parseFocusScrollPadding(focusScrollTarget: Element) {
  // First try to get the padding values from the target element.
  const targetPaddingValues = parsePaddingString(
    getStyle(focusScrollTarget).getPropertyValue(FOCUS_SCROLL_PADDING_CSS_PROPERTY),
  );
  if (targetPaddingValues) {
    return targetPaddingValues;
  }

  // Then try to get the padding values from the root element.
  const rootPaddingValues = parsePaddingString(
    getStyle(document.documentElement).getPropertyValue(FOCUS_SCROLL_PADDING_CSS_PROPERTY),
  );
  if (rootPaddingValues) {
    return rootPaddingValues;
  }

  // Finally, return the default padding values.
  return {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
}
