import { ARROW_NAV_DIRECTION } from '../constants';
import { settings } from '../settings';

export function parseArrowNavDirection(e: KeyboardEvent) {
  if (settings.arrowNavKeys.up.includes(e.key)) return ARROW_NAV_DIRECTION.UP;
  if (settings.arrowNavKeys.down.includes(e.key)) return ARROW_NAV_DIRECTION.DOWN;
  if (settings.arrowNavKeys.left.includes(e.key)) return ARROW_NAV_DIRECTION.LEFT;
  if (settings.arrowNavKeys.right.includes(e.key)) return ARROW_NAV_DIRECTION.RIGHT;
  return undefined;
}
