import { Rectangle } from './types';

export function getDistanceFromRectToRect(rect1: Rectangle, rect2: Rectangle): number {
  // Calculate the maximum starting point and the minimum end point for both x
  // and y.
  const maxStartX = Math.max(rect1.x, rect2.x);
  const minEndX = Math.min(rect1.x + rect1.width, rect2.x + rect2.width);

  const maxStartY = Math.max(rect1.y, rect2.y);
  const minEndY = Math.min(rect1.y + rect1.height, rect2.y + rect2.height);

  // Calculate the distance between rectangles in both x and y directions.
  const xDistance = Math.max(0, maxStartX - minEndX);
  const yDistance = Math.max(0, maxStartY - minEndY);

  // Return Euclidean distance.
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
}
