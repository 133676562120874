import { ARROW_NAV_DIRECTION, ARROW_NAV_TARGET_CLASS } from '../constants';
import { getPointerPosition } from '../systems/pointer-position';
import { focusClosestArrowNavTarget } from './focus-closest-arrow-nav-target';
import { getClosestLayerElement } from './get-closest-layer-element';
import { isElementFocusable } from './is-element-focusable';

export function focusClosestArrowNavTargetToPointer(direction?: ARROW_NAV_DIRECTION) {
  const pointerClientPosition = getPointerPosition();

  // Get the element under the pointer.
  const elementFromPoint = document.elementFromPoint(
    pointerClientPosition.x,
    pointerClientPosition.y,
  );
  if (!elementFromPoint) return;

  // Get the layer element.
  const layerElement = getClosestLayerElement(elementFromPoint);
  if (!layerElement) return;

  // First of all, let's check the easy case. If the element under the pointer
  // is arrow navigable, let's focus it.
  const closestTarget: HTMLElement | null = elementFromPoint.closest(`.${ARROW_NAV_TARGET_CLASS}`);
  if (closestTarget && isElementFocusable(closestTarget) && layerElement.contains(closestTarget)) {
    closestTarget.focus({ preventScroll: true });
    return;
  }

  // Finally let's try the hard way, looking for the closest arrow navigable
  // element visually.
  focusClosestArrowNavTarget(pointerClientPosition, layerElement, direction);
}
