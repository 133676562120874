import { settings } from '../settings';

export function getLayerElements() {
  const layerElements = new Set<HTMLElement>();

  for (const layer of settings.layers) {
    if (typeof layer === 'string') {
      document.querySelectorAll(layer).forEach((element) => {
        if (element instanceof HTMLElement) {
          layerElements.add(element as HTMLElement);
        }
      });
    } else {
      layerElements.add(layer);
    }
  }

  return [...layerElements];
}
