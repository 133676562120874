import { getDistanceFromPointToRect } from './get-distance-from-point-to-rect';
import { getFocusableElements } from './get-focusable-elements';

export function getClosestFocusableElementFromPoint(
  point: {
    x: number;
    y: number;
  },
  layer?: HTMLElement,
): HTMLElement | null {
  const focusableElements = getFocusableElements(layer || document);
  let minDistance = Infinity;
  let closestFocusable: HTMLElement | null = null;
  for (let i = 0; i < focusableElements.length; i++) {
    const focusableElement = focusableElements[i] as HTMLElement;
    const distance = getDistanceFromPointToRect(point, focusableElement.getBoundingClientRect());
    if (distance < minDistance) {
      closestFocusable = focusableElement;
      minDistance = distance;
    }
  }
  return closestFocusable;
}
