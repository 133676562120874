import { isElementFocusable } from './is-element-focusable';

// NB: This also checks the provided element, not just it's ancestors.
export function getFocusableAncestor(
  element: Element | HTMLElement | null,
  layer?: HTMLElement | null,
) {
  // First of all, let's make sure that the layer is the element or an
  // ancestor of the element.
  if (layer && !layer.contains(element)) {
    return null;
  }

  while (element) {
    if (element === document.body || element === document.documentElement) {
      return null;
    }

    if (isElementFocusable(element)) {
      return element as HTMLElement;
    } else if (layer && element === layer) {
      return null;
    }

    element = element.parentElement;
  }

  return null;
}
