import { ARROW_NAV_RECT_ATTRIBUTE } from '../constants';
import { Rectangle } from './types';

export function getArrowNavRect(
  source: HTMLElement | { x: number; y: number },
  layer: HTMLElement,
): Rectangle {
  const isSourceElement = source instanceof HTMLElement;
  if (!isSourceElement) {
    return { width: 0, height: 0, x: source.x, y: source.y };
  }

  const sourceSelector = source.getAttribute(ARROW_NAV_RECT_ATTRIBUTE);
  const sourceElement = (sourceSelector && layer.querySelector(sourceSelector)) || source;
  const sourceRect = sourceElement.getBoundingClientRect();

  return sourceRect;
}
