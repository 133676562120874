export interface NavSystemSettings {
  // The layers define which elements should be considered when navigating
  // between focusable elements. These are basically the root elements of the
  // navigation system and when we traverse the DOM tree we will stop at these
  // elements. In other words, nav system does not automatically move focus
  // between layers by any mechanic, you have to do it manually. The main use
  // case for this is modals, popups and anything you want to apply a focus
  // trap to. In such scenarios you want to trap the nav system into a specific
  // element and you can do it via this option. You can mutate/replace this
  // array at any point to change the layers. Note that you can include either
  // HTMLElements or CSS selectors.
  layers: (HTMLElement | string)[];

  // If true, any focusable element that receives focus within the provided
  // layers will be scrolled into view. This is useful if you have a scrollable
  // container and you want to make sure that the focused element is always
  // visible.
  scrollFocusedIntoView: boolean;

  // If true, the focused element will be blurred when the user interacts with
  // the page using the mouse or touch. You should set this to true if you
  // don't want to have hover and focus state active at the same time.
  blurOnPointerInteraction: boolean;

  // If true, the currently hovered (by pointer device) focusable element (if
  // any) will be clicked when the user presses Enter while navigation device
  // is changed from non-keyboard to keyboard.
  clickHoveredFocusableOnEnter: boolean;

  // Should be a number between 0 and 1. This defines how much of the closest
  // scrollable's height we want to scroll towards the sticky edge when using
  // arrow navigation with sticky container. Set to 0 to disable sticky scroll.
  stickyScrollInterval: number;

  // Arrow navigation keys. This object defines the arrow navigation keys for
  // each direction. You can customize these keys to match your app's needs.
  arrowNavKeys: {
    up: string[];
    down: string[];
    left: string[];
    right: string[];
  };
}

export const settings: NavSystemSettings = {
  layers: typeof window === 'undefined' ? [] : [document.documentElement],
  scrollFocusedIntoView: true,
  blurOnPointerInteraction: false,
  clickHoveredFocusableOnEnter: true,
  stickyScrollInterval: 0.9,
  arrowNavKeys: {
    up: ['ArrowUp', 'w'],
    down: ['ArrowDown', 's'],
    left: ['ArrowLeft', 'a'],
    right: ['ArrowRight', 'd'],
  },
};
