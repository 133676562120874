import { getFocusableElements } from './get-focusable-elements';

export function getPrevFocusableElement(
  focusedElement: Element | null = document.activeElement,
  layer?: HTMLElement,
): HTMLElement | null {
  const focusableElements = getFocusableElements(layer || document);
  if (focusedElement) {
    const currentIndex = Array.from(focusableElements).findIndex((curr) => curr === focusedElement);
    return focusableElements[currentIndex - 1] || null;
  }
  return focusableElements[0] || null;
}
