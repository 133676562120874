import { getNavDevice } from './nav-device';
import { scrollFocusedElementIntoView } from '../utils/scroll-focused-element-into-view';
import { getLayerElements } from '../utils/get-layer-elements';
import { settings } from '../settings';
import { FOCUS_SCROLL_PADDING_CSS_PROPERTY } from '../constants';

const onFocus = (e: FocusEvent) => {
  // Make sure this feature is enabled.
  if (!settings.scrollFocusedIntoView) return;

  // Only scroll if the user is using the keyboard.
  if (getNavDevice() !== 'keyboard') return;

  const targetElement = e.target;

  // Scroll the focused element into view, if it's an Element instance and
  // within any of the current layers.
  if (
    targetElement instanceof Element &&
    getLayerElements().some((layer) => layer.contains(targetElement))
  ) {
    scrollFocusedElementIntoView(targetElement, 'x');
    scrollFocusedElementIntoView(targetElement, 'y');
  }
};

export function start() {
  if (typeof window === 'undefined') return;

  try {
    window.CSS.registerProperty({
      name: FOCUS_SCROLL_PADDING_CSS_PROPERTY,
      syntax: '<length>+',
      inherits: false,
      // We set intentionally incorrect amount of initial values so we can
      // detect if the user has set the property in the first place. Technically
      // you can set any amount of initial values, but our parsing function
      // only supports 1-4 values.
      initialValue: '0px 0px 0px 0px 0px',
    });
  } catch (e) {}

  window.addEventListener('focusin', onFocus, { capture: true });
}

export function stop() {
  if (typeof window === 'undefined') return;
  window.removeEventListener('focusin', onFocus, { capture: true });
}
