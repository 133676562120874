const pointerClientPosition = { x: 0, y: 0 };

const onPointermove = (e: PointerEvent) => {
  pointerClientPosition.x = e.clientX;
  pointerClientPosition.y = e.clientY;
};

export function start() {
  if (typeof window === 'undefined') return;
  window.addEventListener('pointermove', onPointermove, { capture: true });
}

export function stop() {
  if (typeof window === 'undefined') return;
  window.removeEventListener('pointermove', onPointermove, { capture: true });
}

export function getPointerPosition() {
  return pointerClientPosition;
}
