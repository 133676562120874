import { settings } from '../settings';
import { focusClosestArrowNavTarget } from '../utils/focus-closest-arrow-nav-target';
import { focusClosestArrowNavTargetToPointer } from '../utils/focus-closest-arrow-nav-target-to-pointer';
import { getClosestLayerElement } from '../utils/get-closest-layer-element';
import { handleArrowNavStickyScroll } from '../utils/handle-arrow-nav-sticky-scroll';
import { parseArrowNavDirection } from '../utils/parse-arrow-nav-direction';
import { setNavDevice } from './nav-device';

const onKeydown = (e: KeyboardEvent) => {
  // Only handle arrow navigation keys.
  const { up, down, left, right } = settings.arrowNavKeys;
  if (![...up, ...down, ...left, ...right].includes(e.key)) {
    return;
  }

  // Update nav device.
  setNavDevice('keyboard');

  // Disable arrow nav for content editable elements as we need to type there
  // and use arrow keys for moving the cursor.
  if (e.target instanceof HTMLElement && e.target.contentEditable === 'true') {
    return;
  }

  // Disable arrow nav for textareas as we need to type there and use arrow keys
  // for moving the cursor.
  if (e.target instanceof HTMLTextAreaElement) {
    return;
  }

  // Disable arrow nav for selects as we need to type there and use arrow keys
  // for moving the cursor.
  if (e.target instanceof HTMLSelectElement) {
    return;
  }

  // Special handling for inputs.
  if (e.target instanceof HTMLInputElement) {
    switch (e.target.type) {
      case 'button':
      case 'submit':
      case 'reset':
      case 'checkbox':
      case 'radio':
      case 'file':
      case 'hidden':
      case 'image': {
        // Allow all arrow keys for button/checkbox-like inputs.
        break;
      }
      default: {
        // Disable arrow nav for all other inputs as they require typing and
        // and native navigating with arrow keys. You can always use Tab to
        // navigate to the next input.
        return;
      }
    }
  }

  // Let's always prevent default to avoid scrolling the page manually via
  // arrow keys.
  e.preventDefault();

  // Get the arrow nav direction.
  const direction = parseArrowNavDirection(e);

  // If we have a focused element let's always use that as the starting
  // point.
  const { activeElement } = document;
  if (activeElement instanceof HTMLElement && activeElement !== document.body) {
    const layerElement = getClosestLayerElement(activeElement);
    if (!layerElement) return;

    if (!handleArrowNavStickyScroll(activeElement, layerElement, direction)) {
      focusClosestArrowNavTarget(activeElement, layerElement, direction);
    }
  }
  // Otherwise let's use the pointer position as the starting point.
  else {
    focusClosestArrowNavTargetToPointer(direction);
  }
};

export function start() {
  if (typeof window === 'undefined') return;
  window.addEventListener('keydown', onKeydown, { capture: true });
}

export function stop() {
  if (typeof window === 'undefined') return;
  window.removeEventListener('keydown', onKeydown, { capture: true });
}
