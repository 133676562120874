// In cases where you want to scroll to a different element than the one that
// is focused, you can add this attribute to the focusable element's ancestor.
// The scroll to target will be the closest ancestor with this attribute, or the
// element itself if no ancestor has this attribute. This attribute accepts
// scroll axis as a value. Possible values are: "x" and "y". For example,
// when the focused element's ancestor has data-focus-scroll-container="x" then
// that ancestor element will be scrolled into view horizontally while the
// focused element itself will be scrolled into view vertically. By default,
// when no value is specified, the element will be scrolled in both axes.
export const FOCUS_SCROLL_CONTAINER_ATTRIBUTE = 'data-focus-scroll-container';

// In some very special cases you might need to scroll to an element that is
// not an ancestor of the focused element. E.g. imagine you have a card
// element with many buttons within it, but you do not want the buttons nor
// their ancestors to be the scroll to target. You might have a special scroll
// to target element within the card element that is a sibling to all the
// focusable elements. Just first add data-focus-scroll-container to the card
// element and then add this attribute to any descendant of the card element.
// Note that this attribute accepts scroll axis as a value. Possible values
// are: "x" and "y".
export const FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE = 'data-focus-scroll-container-target';

// By default all focused elements will be scrolled into view. If you want to
// disable this behavior for a specific element, you can add this attribute to
// that element. You can also add this attribute to focus scroll container
// element or it's target. This attribute accepts scroll axis as a value.
// Possible values are: "x" and "y". For example, when the focused element has
// data-focus-scroll-disabled="x" then that element will not be scrolled into
// view horizontally, but it will be scrolled into view vertically. By default,
// when no value is specified, the element will not be scrolled in any axis.
export const FOCUS_SCROLL_DISABLED_ATTRIBUTE = 'data-focus-scroll-disabled';

// Focus scroll padding CSS property. This property can be used to define the
// padding around the focused element when it's scrolled into view. The value
// should be a space separated list of lengths, each length value defines the
// padding for the corresponding side of the focused element. The order of the
// values is top, right, bottom, left. You can use shorthand notation as well,
// just like in normal padding.
export const FOCUS_SCROLL_PADDING_CSS_PROPERTY = '--focus-scroll-padding';

// Target class should be added to all arrow navigable elements. Note that
// all arrow navigable elements should be focusable as well.
export const ARROW_NAV_TARGET_CLASS = 'arrow-nav-target';

// Target group class can be used to group arrow navigable elements together.
// In practice this means that if there is an arrow nav target group that is
// visually closer to the focused element than any other normal arrow nav target
// we will then prioritize the closest arrow nav target within the group.
export const ARROW_NAV_TARGET_GROUP_CLASS = 'arrow-nav-target-group';

// In special cases where you have arrow navigable elements that are
// potentially taller than the viewport and you want to scroll the window
// vertically to the closest sticky edge instead of focusing the closest arrow
// navigable element, you can add this class such arrow nav target.
// Do not put any other arrow navigable elements inside the sticky container,
// otherwise arrow nav will not work properly. However, you can put
// focusable elements inside the sticky container and use tab to access them.
export const ARROW_NAV_STICKY_CLASS = 'arrow-nav-sticky';

// Container class should be added to common ancestor of all arrow navigable
// elements that you want to prioritize when navigating with arrow keys. In
// practice this means that we will always try focus to the closest arrow
// navigable element within the container first before falling back to the
// closest arrow navigable element outside the container. This is useful e.g.
// when you have a scrollable container with arrow navigable elements inside
// it and you want to prioritize those elements when navigating with arrow keys.
export const ARROW_NAV_CONTAINER_CLASS = 'arrow-nav-container';

// Ignore attribute should be added to container elements. This will prevent
// arrow nav from navigating to the specified direction within the
// container and propagate the nav to the closest outer container.
// Possible values are: x, y, left, right, up, down. You can also combine
// multiple values by separating them with space. For example:
// data-arrow-nav-ignore="left top" will ignore left/top nav.
export const ARROW_NAV_IGNORE_ATTRIBUTE = 'data-arrow-nav-ignore';

// Contain attribute should be added to container elements. This will prevent
// arrow nav from navigating to the specified direction outside the
// container. Possible values are: x, y, left, right, up, down. You can also
// combine multiple values by separating them with space. For example:
// data-arrow-nav-contain="left top" will contain left/top nav.
export const ARROW_NAV_CONTAIN_ATTRIBUTE = 'data-arrow-nav-contain';

// The rect attribute can be added to any focusable element and it will be
// used to determine the element's client rect for the calculations. The value
// of the attribute should be a selector to the element which's bounding client
// rect data should be used in place of the element's own bounding client rect.
export const ARROW_NAV_RECT_ATTRIBUTE = 'data-arrow-nav-rect';

export enum ARROW_NAV_DIRECTION {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}
