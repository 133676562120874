import { start as startArrowNav, stop as stopArrowNav } from './systems/arrow-nav';
import {
  start as startPointerPosition,
  stop as stopPointerPosition,
} from './systems/pointer-position';
import {
  start as startNavDevice,
  stop as stopNavDevice,
  getNavDevice,
  getPreviousNavDevice,
} from './systems/nav-device';
import { start as startFocusScroll, stop as stopFocusScroll } from './systems/focus-scroll';
import { start as startGamepad, stop as stopGamepad } from './systems/gamepad';
import { NavSystemSettings, settings } from './settings';
import { emitter } from './emitter';

function start() {
  startPointerPosition();
  startNavDevice();
  startArrowNav();
  startFocusScroll();
  startGamepad();
}

function stop() {
  stopPointerPosition();
  stopNavDevice();
  stopArrowNav();
  stopFocusScroll();
  stopGamepad();
}

function updateSettings(newSettings: Partial<NavSystemSettings>) {
  Object.assign(settings, newSettings);
}

function on(...args: Parameters<typeof emitter.on>) {
  return emitter.on(...args);
}

function once(...args: Parameters<typeof emitter.once>) {
  return emitter.once(...args);
}

function off(...args: Parameters<typeof emitter.off>) {
  return emitter.off(...args);
}

export const navSystem = {
  start,
  stop,
  updateSettings,
  on,
  once,
  off,
  getNavDevice,
  getPreviousNavDevice,
} as const;

export {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_TARGET_GROUP_CLASS,
  ARROW_NAV_STICKY_CLASS,
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_IGNORE_ATTRIBUTE,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_RECT_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
  FOCUS_SCROLL_DISABLED_ATTRIBUTE,
  FOCUS_SCROLL_PADDING_CSS_PROPERTY,
} from './constants';

export type { Events } from './emitter';

export type { NavDevice } from './systems/nav-device';
