import { isElementFocusable } from './is-element-focusable';

// This is a rough estimate of what is considered focusable. We'll still need
// to use isElementFocusable to check if the elements are actually focusable.
const FOCUSABLE_QUERY =
  'a[href]:not([tabindex="-1"]), button:not(:disabled, [tabindex="-1"]), input:not(:disabled, [tabindex="-1"]), select:not(:disabled, [tabindex="-1"]), textarea:not(:disabled, [tabindex="-1"]), [tabindex]:not(:disabled, [tabindex="-1"])';

export const getFocusableElements = (container: HTMLElement | Document = document) => {
  return Array.from(container.querySelectorAll(FOCUSABLE_QUERY)).filter(
    isElementFocusable,
  ) as HTMLElement[];
};
