import { useEffect } from 'react';

const CARD_TYPES = ['landscape-1', 'landscape-2', 'portrait-1', 'portrait-2'] as const;

let cardMeasureElement: HTMLElement | null = null;

function computeScrollbarSizes(el: HTMLElement) {
  const w = el.offsetWidth - el.clientWidth;
  const h = el.offsetHeight - el.clientHeight;
  document.documentElement.style.setProperty('--g--root-scrollbar-width', `${w}px`);
  document.documentElement.style.setProperty('--g--root-scrollbar-height', `${h}px`);
}

function computeCardVariables() {
  if (!cardMeasureElement) {
    cardMeasureElement = document.createElement('div');

    const { style } = cardMeasureElement;
    style.position = 'absolute';
    style.left = '-100%';
    style.top = '-100%';
    style.pointerEvents = 'none';
    style.visibility = 'hidden';

    document.body.appendChild(cardMeasureElement);
  }

  CARD_TYPES.forEach((cardType) => {
    const { style } = cardMeasureElement!;
    style.width = `var(--g--card-${cardType}-width)`;
    style.aspectRatio = `var(--g--card-${cardType}-aspect-ratio)`;

    const { width, height } = cardMeasureElement!.getBoundingClientRect();
    document.documentElement.style.setProperty(`--g--card-${cardType}-width-unitless`, `${width}`);
    document.documentElement.style.setProperty(
      `--g--card-${cardType}-height-unitless`,
      `${height}`,
    );
  });
}

export function useDynamicCssVariables() {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const el = document.getElementById('__next');
    if (!el) return;

    const computeVariables = () => {
      computeScrollbarSizes(el);
      computeCardVariables();
    };

    // Recompute variables on __next element resize. Note that this is also
    // triggered on initial render so we don't need to call computeVariables
    // manually.
    const resizeObserver = new ResizeObserver(computeVariables);
    resizeObserver.observe(el);

    return () => {
      resizeObserver.disconnect();
      document.documentElement.style.removeProperty('--g--root-scrollbar-width');
      document.documentElement.style.removeProperty('--g--root-scrollbar-height');
      CARD_TYPES.forEach((cardType) => {
        document.documentElement.style.removeProperty(`--g--card-${cardType}-width-unitless`);
      });
    };
  }, []);
}
