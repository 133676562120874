import { dispatchKeyboardEvent } from '../utils/dispatch-keyboard-event';
import { getClosestLayerElement } from '../utils/get-closest-layer-element';
import { getNextFocusableElement } from '../utils/get-next-focusable-element';
import { getPrevFocusableElement } from '../utils/get-prev-focusable-element';

type KeyboardPress =
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Tab'
  | 'BackTab'
  | 'Enter';
type Presses = Record<KeyboardPress, number>;
type Press = { wasPressed: boolean; timer: number };
type RecordedPresses = Record<number, Press>;

// those values should always be implemented the same way by browsers
// according to https://w3c.github.io/gamepad/#remapping
const STANDARD_PRESSES: Presses = {
  ArrowUp: 12,
  ArrowDown: 13,
  ArrowLeft: 14,
  ArrowRight: 15,
  Tab: 5,
  BackTab: 4,
  Enter: 0,
};

class Gamepad {
  private lastPoll: number = -1;
  private gamepadIndex: number = -1;
  private presses: RecordedPresses;
  private availablePresses: Presses | null = null;

  static EXEC_TIMER = 500;
  static AXE_THRESHOLD = 0.8;
  /**
   * This static method initialise the presses to track.
   */
  static initPresses(): RecordedPresses {
    return Object.values(STANDARD_PRESSES).reduce(
      (acc, curr) => ((acc[curr] = { wasPressed: false, timer: 0 }), acc),
      {} as RecordedPresses,
    );
  }

  /**
   * Create a gamepad.
   *
   * The purpose of this class is to be used as a singleton that we can "connect"
   * or "disconnect" to a gamepad once the 'gamepadconnected' or 'gamepaddisconnected'
   * events have been sent.
   */
  constructor() {
    // init all presses with { wasPressed, timer }
    this.presses = Gamepad.initPresses();
  }

  /**
   * This function will register an existing touch by flipping
   * its timer to `now`.
   *
   * It does not register presses that are not tracked (declared in STANDARD_presses).
   *
   * @param {number} now - a timestamp in ms
   * @param {boolean} wasPressed - tells if the event comes from a pressed button
   * in order to differentiate with axes
   */
  addTouch(id: number, now: number, wasPressed: boolean = false) {
    const touch = this.presses[id];
    const canAdd = !touch || now - touch.timer >= Gamepad.EXEC_TIMER;

    if (canAdd) {
      this.dispatchTouch(id, now, wasPressed);
    }
  }

  /**
   * Return wether or not a gamepad has been connected
   * @return {boolean}
   */
  get connected(): boolean {
    return this.gamepadIndex > -1;
  }

  /**
   * Registers a newly connected gamepad by saving it into the instance
   *
   * It will start polling with an endless rAF in order to check the state of
   * the buttons and axes
   *
   * @param {number} gamepadIndex - the index of the connected gamepad, mostly likely
   * 0 all the time, given by the browser API, see
   * https://developer.mozilla.org/en-US/docs/Web/API/Gamepad
   */
  connect(gamepadIndex: number) {
    const gamepad = navigator.getGamepads()[gamepadIndex];

    this.disconnect();
    this.gamepadIndex = gamepadIndex;
    this.availablePresses = gamepad?.mapping === 'standard' ? STANDARD_PRESSES : null;

    const poll = () => {
      const now = +new Date();
      this.poll(now);
      this.lastPoll = requestAnimationFrame(poll);
    };

    this.lastPoll = requestAnimationFrame(poll);
  }

  /**
   * Removes the gamepad index from the instance and reinitialise presses.
   */
  disconnect() {
    cancelAnimationFrame(this.lastPoll);
    this.gamepadIndex = -1;
    this.presses = Gamepad.initPresses();
  }

  /**
   * Dispatch a "touch".
   *
   * This will check if the touch is part of the tracked presses, saves the
   * timestamp for `now`, saves wether or not the touch was coming from a button
   * press and dispatch the right keyboard event for this touch.
   *
   * @param {number} id - the index of the button/direction
   * @param {number} now - a timestamp in ms
   * @param {boolean} wasPressed - tells if the event comes from a pressed button
   */
  dispatchTouch(id: number, now: number, wasPressed: boolean) {
    if (!this.availablePresses) {
      throw new Error('Gamepad not supported');
    }

    /*
     * We keep the timestamp when the touch has been dispatched in order to
     * throttle events as this can potentially happens a lot and create a fast
     * (and furious) navigation (at the speed of light).
     */
    this.presses[id]!.timer = now;
    /*
     * we need to know wether or not the touch from a button press to differentiate
     * with presses coming from axes so one does not cancel the other
     */
    this.presses[id]!.wasPressed = wasPressed;

    switch (id) {
      case this.availablePresses.ArrowUp:
        dispatchKeyboardEvent({ key: 'ArrowUp', cancelable: true });
        break;
      case this.availablePresses.ArrowDown:
        dispatchKeyboardEvent({ key: 'ArrowDown', cancelable: true });
        break;
      case this.availablePresses.ArrowLeft:
        dispatchKeyboardEvent({ key: 'ArrowLeft', cancelable: true });
        break;
      case this.availablePresses.ArrowRight:
        dispatchKeyboardEvent({ key: 'ArrowRight', cancelable: true });
        break;
      case this.availablePresses.Enter:
        {
          const e = dispatchKeyboardEvent({ key: 'Enter', cancelable: true });
          if (!e.defaultPrevented) {
            const { activeElement } = document;
            if (activeElement instanceof HTMLElement) {
              activeElement.click();
            }
          }
        }
        break;
      case this.availablePresses.Tab:
        {
          const e = dispatchKeyboardEvent({ key: 'Tab', cancelable: true });
          if (!e.defaultPrevented) {
            const { activeElement } = document;
            if (activeElement instanceof HTMLElement) {
              const layerElement = getClosestLayerElement(activeElement);
              if (layerElement) {
                const nextActiveElement = getNextFocusableElement(activeElement, layerElement);
                if (nextActiveElement instanceof HTMLElement) {
                  nextActiveElement.focus({ preventScroll: true });
                }
              }
            }
          }
        }
        break;
      case this.availablePresses.BackTab:
        {
          const e = dispatchKeyboardEvent({ key: 'Tab', shiftKey: true, cancelable: true });
          if (!e.defaultPrevented) {
            const { activeElement } = document;
            if (activeElement instanceof HTMLElement) {
              const layerElement = getClosestLayerElement(activeElement);
              if (layerElement) {
                const prevActiveElement = getPrevFocusableElement(activeElement, layerElement);
                if (prevActiveElement instanceof HTMLElement) {
                  prevActiveElement.focus({ preventScroll: true });
                }
              }
            }
          }
        }
        break;
    }
  }

  /**
   * The heart of the gamepad.
   *
   * poll is called every frame in order to check the state of the gamepad
   *
   * @param {number} now - a timestamp in ms
   */
  poll(now: number) {
    // Make sure the document has focus before we do anything.
    if (!document.hasFocus()) return;

    const gamepad = navigator.getGamepads()[this.gamepadIndex];
    const [horizontal = 0, vertical = 0] = gamepad?.axes || [];

    /*
     * For vertical and horizontal left axes, we need to check wether or not
     * the direction is positive or negative as well as if it's over a certain
     * threshold.
     */
    const directionX =
      horizontal > Gamepad.AXE_THRESHOLD ? 1 : horizontal < -1 * Gamepad.AXE_THRESHOLD ? -1 : 0;
    const directionY =
      vertical > Gamepad.AXE_THRESHOLD ? 1 : vertical < -1 * Gamepad.AXE_THRESHOLD ? -1 : 0;

    /*
     * Then we register these as normal arrow presses and later unregister them
     *
     * /!\ We have to make sure we check the previously registered touch was
     * "not pressed"
     */
    if (directionX > 0) {
      this.addTouch(STANDARD_PRESSES.ArrowRight, now);
    } else if (directionX < 0) {
      this.addTouch(STANDARD_PRESSES.ArrowLeft, now);
    } else {
      if (!this.presses[STANDARD_PRESSES.ArrowLeft]!.wasPressed) {
        this.presses[STANDARD_PRESSES.ArrowLeft]!.timer = 0;
      }
      if (!this.presses[STANDARD_PRESSES.ArrowRight]!.wasPressed) {
        this.presses[STANDARD_PRESSES.ArrowRight]!.timer = 0;
      }
    }

    if (directionY > 0) {
      this.addTouch(STANDARD_PRESSES.ArrowDown, now);
    } else if (directionY < 0) {
      this.addTouch(STANDARD_PRESSES.ArrowUp, now);
    } else {
      if (!this.presses[STANDARD_PRESSES.ArrowUp]!.wasPressed) {
        this.presses[STANDARD_PRESSES.ArrowUp]!.timer = 0;
      }
      if (!this.presses[STANDARD_PRESSES.ArrowDown]!.wasPressed) {
        this.presses[STANDARD_PRESSES.ArrowDown]!.timer = 0;
      }
    }

    /*
     * Finally, we check the gamepad button
     */
    gamepad?.buttons.forEach(({ pressed }, i) => {
      if (!this.presses[i]) return;

      if (pressed) {
        this.addTouch(i, now, true);
      } else if (!!this.presses[i] && this.presses[i]!.wasPressed) {
        // if the same presses is not pressed but was pressed, we set its timer
        // to 0 to free its usage
        this.presses[i]!.timer = 0;
      }
    });
  }
}

const gamepad = new Gamepad();

function onGamepadConnected(e: GamepadEvent) {
  gamepad.connect(e.gamepad.index);
}

function onGamepadDisconnected() {
  gamepad.disconnect();
}

export function start() {
  if (typeof window === 'undefined') return;
  window.addEventListener('gamepaddisconnected', onGamepadDisconnected);
  window.addEventListener('gamepadconnected', onGamepadConnected);
}

export function stop() {
  if (typeof window === 'undefined') return;
  window.removeEventListener('gamepaddisconnected', onGamepadDisconnected);
  window.removeEventListener('gamepadconnected', onGamepadConnected);
}
