import { NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';
import { initApollo } from '../utils/apollo-client';

interface PageProps {
  __APOLLO_CACHE__?: NormalizedCacheObject;
}

export function useApollo(pageProps: PageProps) {
  const apolloCache = pageProps.__APOLLO_CACHE__ || null;
  const store = useMemo(() => initApollo(apolloCache), [apolloCache]);
  return store;
}
